@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--col-secondary);
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  background-color: var(--col-secondary);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--col-primary);
}

:root {
  --col-primary: #b02e26;
  --col-secondary: #171717;
  --col-text: #fefefe;
  --col-accent: #F9801D;

  --margin: 0;
  --margin-neg: 0;

  --header-height: 100px;

  font-size: 12px;
  scroll-behavior: smooth;
}

section {
  padding-left: var(--margin);
  padding-right: var(--margin);

  h3 {
    text-align: center;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

html,
body,
#root,
#app {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.slider {
  height: 100%;
  margin-top: 15px;

  .track-0 {
    height: calc(100% - 20px);
    width: 6px;
    transform: translateX(-50%);
    background-color: #383838;
  }

  .track-1 {
    left: 15px;
    height: 16px;
    line-height: 16px;
    transform: translateY(-50%);
  }

  .thumb {
    width: 20px;
    height: 20px;
    background-color: var(--col-primary);
    transform: translateX(-50%) translateY(-50%);

    &:focus,
    &:active {
      outline: none;
    }
  }

  .mark {
    height: 2px;
    width: 18px;
    transform: translateX(-50%);
    background-color: #383838;
  }
}


@media (min-width: 576px) {}

@media (min-width: 768px) {
  :root {
    --margin: 5vw;
    --margin-neg: -5vw;
    font-size: 16px;
  }

  section {
    h3 {
      text-align: left;
    }
  }
}

@media (min-width: 992px) {
  :root {
    --margin: 5vw;
    --margin-neg: -5vw;
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  :root {
    --margin: 10vw;
    --margin-neg: -10vw;
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
  :root {
    --margin: 15vw;
    --margin-neg: -15vw;
    font-size: 16px;
  }
}
